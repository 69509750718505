import "../styles/globals.css";
import "../styles/normlize.css";

import * as React from "react";
import MainWrapper from "../components/mainWrapper";
import MainTitle from "../components/mainTitle";
import SitesAndChannels from "../components/sitesAndChannels";
import { Helmet } from "react-helmet";


const IndexPage = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <meta name="description"
                      content="Pfff network, adult websites, twitter and telegram channels"/>
                <title>Pfff - bon bon boner</title>
            </Helmet>
            <MainWrapper>
                <MainTitle/>
                <SitesAndChannels/>
            </MainWrapper>
        </>
    );
};

export default IndexPage;

export const Head = () => <title>Pfff network</title>;
