import React from "react";
import styled from "styled-components";

const ContentStyled = styled.div`
  text-align: center;

  h2 {
    font-size: 5rem;
    margin-top: 10%;
    margin-bottom: 3%;

    @media (max-width: 780px) {
      font-size: 2rem;
    }
  }
`;

const SiteStyled = styled.div`
  display: grid;
  //justify-self: end;

  &.left {
    //justify-self: start;
  }

  a {
    font-size: 2rem;
  }

  p {
    font-size: 2rem;
  }

  blockquote {
    font-size: 1.3rem;
    font-style: italic;
    width: 70%;
    margin: 2% auto;
  }
`;

const TwoGridRowStyled = styled.div`
  display: grid;
  grid-gap: 110px;
  justify-items: center;
`;

const ChanelsStyled = styled.div`
  padding-bottom: 40px;
  
  h5 {
    font-size: 1.5rem;
  }
  a{
    font-size: 2rem;
    display: block;
    width: fit-content;
    margin: 0 auto 8px;
  }
`;

const EStyled = styled.div`
  color: rgb(246, 143, 190);
  font-size: 10rem;
`;

const SitesAndChannels = () => {
    return (
        <ContentStyled>
            <h2>WebSites</h2>
            <TwoGridRowStyled>
                <SiteStyled>
                    <h3><a href="https://juicyelf.com" target="_blank" rel="noreferrer">JuicyElf</a></h3>
                    <p>Best porn ever, ready to get a boner?</p>
                    <EStyled>
                        18+
                    </EStyled>
                </SiteStyled>

                <SiteStyled className="left">
                    <h3><a href="https://textmesex.com" target="_blank" rel="noreferrer">TextMeSex</a></h3>
                    <p>Best sex stories keep your dick hard</p>
                    <blockquote>
                        "Then I carefully removed the shirt, which had become completely unnecessary, and my gaze opened
                        up to my mother's magnificent, covered with a lawn of black hair, my mother's "pussy". It was
                        the greatest sight! Two pale pink lips were looking at me, covering a small petal of the
                        clitoris."
                    </blockquote>
                </SiteStyled>
            </TwoGridRowStyled>
            <h2>Channels</h2>
            <ChanelsStyled>
                <h5>Porn</h5>
                <a href="https://t.me/pfff_lesb" target="_blank" rel="noreferrer">Lesbians</a>
                <a href="https://t.me/pfff_trans" target="_blank" rel="noreferrer">Trans and shemales</a>
                <a href="https://t.me/pfff_tiktok" target="_blank" rel="noreferrer">Tiktok whores</a>
                <a href="https://t.me/japanese_korean_asian_porn" target="_blank"
                   rel="noreferrer">Asians</a>
                <a href="https://t.me/pfff_nsfw_funny" target="_blank" rel="noreferrer">Porn can be
                    funny</a>
                <a href="https://t.me/pfff_boom" target="_blank" rel="noreferrer">BEST porn</a>
                <a href="https://t.me/pfff_nude_celebs" target="_blank" rel="noreferrer">Nude celebs</a>
                <a href="https://t.me/b_babes" target="_blank" rel="noreferrer">Beauties</a>
                <a href="https://t.me/pfff_bw" target="_blank" rel="noreferrer">B&W</a>
                <a href="https://t.me/pfff_sex_story" target="_blank" rel="noreferrer">SEX story</a>
                <h5>Hentai</h5>
                <a href="https://t.me/pfff_hentai" target="_blank" rel="noreferrer">Hentai</a>
                <a href="https://t.me/futa_pfff" target="_blank" rel="noreferrer">Futa, trap</a>
                <a href="https://t.me/yaoi_pfff" target="_blank" rel="noreferrer">Yaoi</a>
                <a href="https://t.me/pfff_yuri" target="_blank" rel="noreferrer">Yuri</a>
                <a href="https://t.me/pfff_cosplay" target="_blank" rel="noreferrer">Cosplay</a>
                <h5>Non Porn</h5>
                <a href="https://t.me/pfff_wall" target="_blank" rel="noreferrer">Wallpapers And
                    Art</a>
                <a href="https://t.me/anime_pfff" target="_blank" rel="noreferrer">Anime</a>
                <a href="https://t.me/pfff_history" target="_blank" rel="noreferrer">We are the
                    History</a>
                <a href="https://t.me/pfff_news" target="_blank" rel="noreferrer">Fucking News</a>
                <a href="https://t.me/pfff_funny_memes_gifs" target="_blank" rel="noreferrer">Trumps
                    Haircut (Funny)</a>
                <a href="https://t.me/pfff_games" target="_blank" rel="noreferrer">Video games</a>
                <a href="https://t.me/pfff_ellie" target="_blank" rel="noreferrer">Ellie Williams
                    (last of us)</a>
                <h5>Music</h5>
                <a href="https://t.me/world_music_pfff" target="_blank" rel="noreferrer">World
                    Music</a>
                <a href="https://t.me/metal_music_pfff" target="_blank" rel="noreferrer">Metal
                    Bunker</a>
            </ChanelsStyled>
        </ContentStyled>
    );
};

export default SitesAndChannels;
