import React from "react";
import styled from "styled-components";

const MainStyled = styled.main`
  margin-bottom: 20px;
`;


const MainWrapper = ({ children }) => {
    return (
        <MainStyled>
            { children }
        </MainStyled>
    );
};

export default MainWrapper;
