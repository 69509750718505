import React from "react";
import AnimatedText from "react-animated-text-content";
import styled from "styled-components";

const WrapperStyled = styled.div`
  font-size: 10rem;
  text-align: center;
  color: rgb(246, 143, 190);
  margin-top: 20%;

  @media (max-width: 780px) {
    margin-top: 0;
  }
`;

const RestStyled = styled.span`
  color: #DFE5EF;

  @media (max-width: 780px) {
    font-size: 5rem;
  }
`;

const MainTitle = () => {
    return (
        <WrapperStyled>
            <AnimatedText
                type="chars" // animate words or chars
                animation={ {
                    x: "200px",
                    y: "-20px",
                    scale: 1.1,
                    ease: "ease-in-out",
                } }
                animationType="bounce"
                interval={ 0.06 }
                duration={ 0.8 }
                tag="span"
                className="animated-paragraph"
                includeWhiteSpaces
                threshold={ 0.1 }
                rootMargin="20%"
            >
                PFFF
            </AnimatedText>
            <RestStyled>
                NETWORK
            </RestStyled>
        </WrapperStyled>
    );
};

export default MainTitle;
